import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate, ValidateIf } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";
import { Common } from "../Common";

export class AccountForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) acc = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) pwd = '';
    oriPwd = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) name = '';
    @IsDefined() @MaxLength(100, { message: "最多輸入100字" }) note = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) identity = '';
    cid = '';
    @ValidateIf(o => o.identity === '2' || o.identity === '3') @IsDefined() @IsNotEmpty({ message: "必填" }) sid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = '';
    aaid = '';
    token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminAccountEditController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminAccountEditController extends Vue {
    private doubleClick = false;
    private accForm = new AccountForm();
    private cityList: object = {};
    private schoolList: object = {};
    private oriPwd = "";
    private errorMsg = "";
    private openLB = false;
    private errNo!: number;
    private token = window.localStorage.getItem('adminToken') as string;
    private identity = window.localStorage.getItem('admin_identity') as string;

    private errorMsgMap: { [key: string]: string } = {
        acc: '',
        pwd: '',
        name: '',
        note: '',
        identity: '',
        sid: '',
        active: '',
    };

    public async created() {
        const item = {
            aaid: this.$route.query.aaid,
            token: this.token,
        }
        const editData = await AccountModel.get(item) as response;
        this.assignDataToForm(editData);

        this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        const cid = this.accForm.cid;
        const identity = this.accForm.identity;
        this.getIdentitySchool(cid, identity);
    }

    private assignDataToForm(data: response) {
        Common.checkToken(data.ERR_CODE as number);

        const item = data.info as { [key: string]: string };
        this.accForm.aaid = item.aaid;
        this.accForm.acc = item.acc;
        this.accForm.pwd = '********';
        this.accForm.oriPwd = item.pwd;
        this.accForm.name = item.name;
        this.accForm.note = item.note;
        this.accForm.identity = item.identity;
        this.accForm.note = item.note;
        this.accForm.cid = item.cid;
        this.accForm.sid = item.sid;
        this.accForm.active = item.active;
    }

    //取得 該身份&縣市下的分校
    private async getSchool() {
        this.accForm.sid = '';
        const cid = this.accForm.cid;
        const identity = this.accForm.identity;
        if (cid !== '') {
            this.getIdentitySchool(cid, identity);
        }
    }

    private async getIdentitySchool(cid: string, identity: string) {
        const item: { [key: string]: string } = {
            cid: cid,
            category: identity,
            token: this.token,
        }
        const data = await AccountModel.getIdentitySchool(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.schoolList = data.list as object;
    }

    private validateAndSubmit() {
        validate(this.accForm, { skipMissingProperties: true }).then(
            async result => {

                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        acc: '',
                        pwd: '',
                        name: '',
                        note: '',
                        identity: '',
                        sid: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = JSON.parse(JSON.stringify(this.accForm));

                    this.errNo = await AccountModel.edit(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.go(0);
        }
    }

}
